$(document).ready(function(){
    $(".trigger-loading").on("click", function(){
        var loading = $($(this).data("loading"));
        var postLoadingContent = $($(this).data("post-loading"));

        loading.css("display", "block");

        setTimeout(function(){
            loading.css("display", "none");
            postLoadingContent.css("display", "block");
        }, 3000);
    });

    $(".show-hide").on("click", function(){
        var show = $($(this).data("show"));
        var hide = $($(this).data("hide"));

        show.css("display", "block");
        hide.css("display", "none");
    });

    $('.open-fixed-nav').on("click", function(){
        $("#fixed-nav").toggleClass("open");
        $("#fixed-nav-btn").toggleClass("open");
    });

    $("#fixed-nav > a").on("click", function(){
        $("#fixed-nav").removeClass("open");
        $("#fixed-nav-btn").removeClass("open");
    });

    var $sidebar = $('#fixed-nav');
    var offsetTop = 125;
    var offsetBottom = 200;
    var $window = $(window);

    if($sidebar){
        $window.on("scroll", function() {
            var scrollTop = $window.scrollTop();
            var sidebarHeight = $sidebar.outerHeight();
            var documentHeight = $(document).height();
            var windowHeight = $window.height();
    
            if (scrollTop > offsetTop && scrollTop + windowHeight < documentHeight - offsetBottom) {
                $sidebar.addClass('fixed');
                $sidebar.removeClass('bottom');
            } else {
                $sidebar.removeClass('fixed');

                if(scrollTop + windowHeight > documentHeight - offsetBottom){
                    $sidebar.addClass('bottom');
                } else {
                    $sidebar.removeClass('bottom');
                }
            }
        });
    }
});